@import url('https://fonts.googleapis.com/css2?family=Lato&family=Playfair+Display&family=Poppins&display=swap');

// colors

$black: #343434;
$purple: #803464;
$blue: #403450;

// gradient
$gradient: linear-gradient(90deg, rgba(64, 52, 80, 1) 0%, rgba(124, 52, 100, 1) 100%);

$buy-gradient: linear-gradient(90deg, rgb(241, 186, 91) 0%, rgb(244, 169, 89) 50%, rgb(250, 140, 62) 100%);

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;

// font-family
$header-font: 'Roboto', sans-serif;
$font-2: 'Lato', sans-serif;
$font-3: 'Playfair Display', serif;
$font-4: 'Poppins', sans-serif;