@import 'variables.scss';
@import 'mixins.scss';
@import 'animations.scss';

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: inherit;
}

p,
li,
a {
    font-family: Font3;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font;
}

section {
    padding: 3% 0;
    overflow: hidden;
}


.section-title {
    text-align: center;
    padding-bottom: 30px;

    h2 {
        font-size: 3vw;
        font-weight: bold;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: $purple;
        font-family: $font-3;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 120px;
            height: 1px;
            background: rgba($color: #000, $alpha: 0.1);
            bottom: 1px;
            left: calc(50% - 60px);
        }

        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 40px;
            height: 3px;
            background: $purple;
            bottom: 0;
            left: calc(50% - 20px);
        }
    }

    p {
        margin-bottom: 0;
    }
}

p {
    font-family: $font-2;
}

* {
    transition: .5s all ease;
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# Privacy Policy
--------------------------------------------------------------*/
.privacy-policy {
    margin-top: 12%;
    padding: $padding;

    p {
        font-size: 16px;
        line-height: 150%;
        color: rgba($color: #000, $alpha: 0.8);

        b {
            color: #000;
        }
    }

    ol {
        padding-left: 1.5%;

        li {
            font-size: 18px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
            margin-bottom: 2%;
            list-style-type: unset;
            color: #000;
            margin-top: 3%;
        }
    }

    ul {

        // padding-left: 0;
        li {
            font-size: 16px;
            margin-top: 0;

            p {
                margin: 0;
                margin-top: 1%;

                a {
                    font-weight: bold;
                }
            }
        }
    }

    // p {
    // 	font-size: 1.2vw;
    // 	// font-family: $font-2;
    // 	line-height: 180%;
    // }

    // ol {
    // 	padding-left: 2%;
    // 	li {
    // 		list-style-type: unset;
    // 		font-size: 2vw;
    // 		margin-bottom: 2%;
    // 		// font-family: $font-3;
    // 		font-weight: bold
    // 	}
    // }

    // ul {
    // 	padding-left: 0;
    // 	li {
    // 		list-style-type: lower-alpha;
    // 		font-size: 1.4vw;

    // 		a {
    // 			color: $blue;
    // 			font-weight: bold;
    // 			// font-family: $font-3;
    // 		}

    // 		p {
    // 			font-size: 1.2vw;
    // 			margin-top: 1%;
    // 			font-weight: normal;
    // 			// font-family: $font-2;
    // 			line-height: 180%;
    // 		}
    // 	}
    // }
}


/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) {
    .section-title {
        h2 {
            font-size: 7vw;
        }
    }

    .privacy-policy {
        margin-top: 20%;

        ol {
            padding-left: 5%;
            li{
                margin-top: 10%;
            }
        }

        ul {
            padding-left: 4%;
            margin-top: 5%;
            li{
                margin-top: 5%;
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    .section-title {
        h2 {
            font-size: 7vw;
        }
    }

    .privacy-policy {
        margin-top: 15%;

        ol {
            padding-left: 2.5%;

            li {
                margin-top: 5%;
            }
        }

        ul {
            padding-left: 4%;
            margin-top: 3%;

            li {
                margin-top: 0;
            }
        }
    }


}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {
    .section-title {
        h2 {
            font-size: 7vw;
        }
    }

    .privacy-policy {
        margin-top: 15%;

        ol {
            padding-left: 5%;
        }

        ul {
            padding-left: 4%;
            margin-top: 5%;
        }
    }

}

@media (min-width: 768px) and (max-width: $lg) and (orientation: landscape) {


    .privacy-policy {
        margin-top: 15%;
    }
}

@media (min-width: 768px) and (max-width: $lg) and (orientation: portrait) {

    .privacy-policy {
        margin-top: 15%;

    }
}

@media (min-width: 1025px) and (max-width: $xl) {}